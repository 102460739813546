
import React from "react";
import background from "assets/img/homepage.jpg"
import logo from "assets/img/GFC_logo.png"
// reactstrap components
import { Button, Container } from "reactstrap";
const buttonStyle = {
    margin: "0.5em"
}

function HomePageHeader() {
    let pageHeader: any = React.createRef();

    React.useEffect(() => {
        if (window.innerWidth < 991) {
            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                pageHeader.current.style.transform =
                    "translate3d(0," + windowScrollTop + "px,0)";
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
    });
    return (
        <>
            <div
                style={{
                    backgroundImage:
                        "url(" + require("assets/img/homepage.jpg").default + ")",
                }}
                className="page-header"
                data-parallax={true}
                ref={pageHeader}
            >
                <div className="filter" />
                <Container>
                    <div className="motto text-center">
                        <img
                            alt="..."
                            src={logo}
                            width="50%"
                        />
                        {/* h3 with a funny catering qoute */}
                        <h3>
                            Serving the best food for your family and friends for over 20 years.
                        </h3>
                        <br />


                        {/* <Button className="btn-round" color="neutral" type="button" style={buttonStyle} outline>
                            Gallery
                        </Button> */}
                        <Button className="btn-round" color="neutral" type="button" style={buttonStyle} onClick={() => {
                            window.location.href = "#menu"
                        }} outline>
                            Menu
                        </Button>
                        <Button className="btn-round" color="neutral" type="button" style={buttonStyle} onClick={() => {
                            window.location.href = "#contactus"
                        }} outline>
                            Contact Us
                        </Button>
                    </div>
                </Container>
            </div>
        </>
    );

}


export default HomePageHeader;