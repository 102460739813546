import { Row, Col, Container } from "reactstrap"
import { useState } from "react"
import { useEffect } from "react";
import MenuCategoryTitle from "./MenuCategoryTitle";
import Accordian from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const apptizers = [{
    name: "Cheese and Fruit",
    description: "A variety gourmet cheeses decorate with fruit",
},
{
    name: "Vegetable Platters",
    description: "A variety of seasonal vegetables companied with Ranch or bleu cheese dip",
},
{
    name: "Fruit Platters",
    description: "Melons, pineapple, grapes, and berries",
}, {
    name: "Grilled Portabella Mushroom",
    description: "Seasoned, topped with Parmesan cheese, garlic butter, and grilled",
}, {
    name: "Cheese Quesadilla Trumpets",
    description: "Fresh julienne vegetables, fresh cilantro, Monterey Jack and cheddar cheese rolled in a flour   tortilla trumpet",
}, {
    name: "Mushroom Profiteroles",
    description: "Chopped mushrooms, walnuts, cream cheese and seasonings in a mini cream puff",
}, {
    name: "Portabella Truffle Crostini",
    description: "Sliced grilled portabella and grilled red bell pepper on top of a cream cheese crostini drizzled   with white truffle oil"
}, {
    name: "Salami and Cream Cheese Rollups",
    description: "",
}, {
    name: "Bruschetta Crostini",
    description: "Diced tomatoes, green and black olives and basil on a crostini",
}, {
    name: "Shrimp Canapé",
    description: "Salad shrimp, feta, green onion, and cilantro on a crostini",
}, {
    name: "Kalamata Olive Artichoke Heart Tart",
    description: "Kalamata olives, artichokes and goat cheese in an tart shell",
}, {
    name: "Olive Platters",
    description: "A variety of Kalamata green and black olives",
}, {
    name: "Shrimp Platters",
    description: "Decorative platters of shrimp with a tangy cocktail sauce",
}, {
    name: "Goat Cheese, Honey, and Herbs",
    description: "Goat cheese log rolled in French herbs topped with honey, served with crackers",
}, {
    name: "Chips, Salsa, and Guacamole",
    description: "",
}, {
    name: "BBQ Oysters",
    description: "Garlic, butter, and pesto sauce",
}, {
    name: "Insalata Caprese",
    description: "Tomato, fresh mozzarella, and basil",
},
]
const salads: ({
    name: string;
    description: string;
})[] = [

        {
            name: "Insalata Caprese",
            description: "Tomato, fresh mozzarella, and basil"
        }, {
            name: "Mixed Field Greens with Fruit and Feta Cheese",
            description: "A tossed salad with raspberry vinaigrette dressing"
        }, {
            name: "Caesar Salad",
            description: "Romaine lettuce, artichoke hearts, olives, and a zesty dressing"
        }, {
            name: "Summer Salad",
            description: "Mixed lettuce with tomatoes, cucumber, onions, shredded carrots, and dressing of choice"
        }, {
            name: "Greek Salad",
            description: "Romaine, green olives, red onion, cucumber, tomatoes, with citrus vinaigrette"
        }, {
            name: "Pasta Salad",
            description: "Pasta, cheese, vegetables, in a cheesy Italian dressing"
        }, {
            name: "Potato Salad",
            description: "",
        }, {
            name: "Spinach Salad",
            description: "Bacon, hard-boiled eggs, and onions over a bed of fresh spinach",
        }, {
            name: "Mediterranean Bean Salad",
            description: "A variety of beans in a Greek dressing",
        }, {
            name: "Fruit Salad",
            description: "Melons, berries, pineapple, grapes, and seasonal fruits",
        }
    ]
const entrees = [
    {
        name: "Beef",
        items: [
            {
                name: "Marinated Tri Tip",
                description: "Wine, ginger, and garlic marinated"
            }, {
                name: "Seasoned Tri Tip",
                description: ""
            }, {
                name: "Sorrento Tri Tip",
                description: "Marinated with lemon juice and Italian seasoning"
            }, {
                name: "Filet Mignon Steak",
                description: ""
            }, {
                name: "New York Steak",
                description: ""
            }, {
                name: "Rib Eye Steak",
                description: ""
            }, {
                name: "Smoked Prime Rib",
                description: "Slow-roasted to perfection and custom-carved"
            }, {
                name: "Texas Style Brisket",
                description: "Slow smoked for 12 hours with a traditional Texas rub"
            }, {
                name: "Spicy itlian beef sandwich",
                description: "Slow braised beef in a spicy pepperoncini sauce"
            },
        ]
    },
    {
        name: "Pork",
        items: [
            {
                name: "Baby Back Ribs",
                description: "Slow-smoked with hickory and lightly sauced, falling off the bone"
            }, {
                name: "Bone-In Prime Rib of Pork",
                description: "Garlic stuffed with raspberry chipotle sauce"
            }, {
                name: "Boneless Center Cut Pork Loin",
                description: "Garlic-stuffed with raspberry chipotle sauce"
            }, {
                name: "BBQ Pork Chops",
                description: ""
            }
        ]
    },
    {
        name: "Chicken",
        items: [
            {
                name: "Sesame Chicken",
                description: "Chicken thighs marinated in sesame teriyaki sauce"
            }, {
                name: "Rosemary Chicken",
                description: "Chicken breasts in a rosemary Italian marinade"
            }, {
                name: "Mango Salsa",
                description: "Teriyaki chicken breast topped with fresh mango salsa"
            }, {
                name: "Hickory Chicken",
                description: "Whole chickens brined, seasoned, and slow-smoked to perfection"
            }, {
                name: "Chicken Piccata",
                description: "Chicken breasts sautéed in lemon, garlic, wine, butter, and  capers"
            }, {
                name: "Chicken Marsala",
                description: "Boneless, skinless chicken breast in Marsala sauce"
            }, {
                name: "Cajun Chicken",
                description: "Chicken breast or thighs, grilled and marinated in Cajun seasonings"
            }, {
                name: "Jerk Style Chicken",
                description: "Jerks seasoned chicken breast Traditional Barbecue Chicken Breasts or Thighs With a sweet and tangy barbecue sauce"
            }, {
                name: "Baja Chicken",
                description: "Marinated chicken breasts with south-of-the-border seasoning"
            },
        ]
    },
    {
        name: "SeaFood",
        items: [
            {
                name: "Salmon",
                description: "Lightly seasoned, with teriyaki apricot ginger glaze"
            }, {
                name: "Grilled Tequila Lime Shrimp",
                description: ""
            }, {
                name: "Scampi",
                description: "Garlic, lemon, dill, and butter sauce"
            }, {
                name: "Lobster",
                description: "Served with drawn butter"
            },
        ]
    }
]
const sides = [
    {
        name: "Potatoes and Rice",
        items: [
            {
                name: "Rustic Garlic Mashed Potatoes",
                description: "Red-skinned potatoes flavored with butter, garlic, sour cream and parsley"
            }, {
                name: "Creamy Mashed Potatoes",
                description: "Traditional mashed potatoes"
            }, {
                name: "Jambalaya Rice",
                description: "Rice, hot links, tomatoes, bell peppers, and onions make up  this traditional Southern dish"
            }, {
                name: "Rice Pilaf",
                description: "Green onions and slivered almonds in a traditional rice pilaf"
            }, {
                name: "White or Brown Rice",
                description: ""
            }, {
                name: "Oven Roasted Red Potatoes",
                description: "Slowly smoked in wood ovens with bacon, onions, and seasonings"
            }, {
                name: "Au Gratin Potatoes",
                description: "Cheesy and creamy"
            },
        ]


    }, {
        name: "Pastas",
        items: [
            {
                name: "Pesto",
                description: ""
            }, {
                name: "Marinara",
                description: ""
            }, {
                name: "Chicken Piccata Pasta",
                description: "Penne pasta with a white piccataa sauce and grilled chicken"
            }, {
                name: "Italian Sausage Red Sauce",
                description: ""
            }
        ]
    }, {
        name: "Vegetables",
        items: [
            {
                name: "Seasonal Grilled Mixed Vegetables",
                description: "Squash, zucchini, bell peppers, onions, and asparagus"
            }, {
                name: "Asparagus",
                description: "Lightly grilled and seasoned"
            }, {
                name: "Sweet carrots",
                description: "Steamed baby carrots with brown sugar glaze"
            }, {
                name: "Green beans",
                description: "Steamed, mixed with bacon, butter, and slivered almonds"
            }, {
                name: "Cheesy Broccoli",
                description: "Steamed, and covered in cheddar cheese"
            }, {
                name: "Brussels Sprouts",
                description: "Steamed, toped with lemon juice"
            }, {
                name: "Fresh Corn on the Cob",
                description: ""
            },
        ]
    },
]
const desserts = [
    {
        name: "Apple Pie",
    },
    {
        name: "Carrot Cake",
    },
    {
        name: "Cookies, Mixed",
    },
    {
        name: "Cheese Cakes",
    },
    {
        name: "Tiramisu",
    },
    {
        name: "Brownies",
    }
]
function Appetizers() {
    let food: any = [];
    for (let i = 0; i < apptizers.length; i++) {
        food.push(
            <div key={`Apptizers${i}`}>
                <p>
                    <b>
                        {apptizers[i].name}
                    </b>
                </p>
                <p>
                    {apptizers[i].description}
                </p>
            </div>

        )
    }
    return <div style={{ width: "100%" }}>
        {food}
    </div>
}
function Salads() {
    let food: any = [];
    for (let i = 0; i < salads.length; i++) {
        food.push(
            <div key={`Salads${i}`}>
                <p>
                    <b>
                        {salads[i].name}
                    </b>
                </p>
                <p>
                    {salads[i].description}
                </p>
            </div>
        )
    }
    return <div style={{ width: "100%" }}>
        {food}
    </div>
}
function Entrees() {
    let food: any = [];
    for (let i = 0; i < entrees.length; i++) {
        let temp = []
        for (let j = 0; j < entrees[i].items.length; j++) {
            temp.push(
                <div key={`Entrees${i}-${j}`} style={{ display: "block", width: "100%" }}>
                    <p>
                        <b>
                            {entrees[i].items[j].name}
                        </b>
                    </p>
                    <p>
                        {entrees[i].items[j].description}
                    </p>
                </div>

            )
        }
        food.push(
            <Accordian style={{ width: "100%" }} >
                <AccordionSummary key={`Entrees${i}`}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                        backgroundColor: "#f5f5f5",
                        border: "1px solid #e0e0e0",
                    }}
                >
                    {entrees[i].name}
                </AccordionSummary>)
                <AccordionDetails key={`Entrees${i}-A`}>
                    <div style={{ display: "block", width: "100%" }}>
                        {temp}
                    </div>

                </AccordionDetails>
            </Accordian>

        )



    }


    return <div style={{ width: "100%" }}>
        {food}
    </div>
}
function Sides() {
    let food: any = [];
    for (let i = 0; i < sides.length; i++) {
        let temp = []
        for (let j = 0; j < sides[i].items.length; j++) {
            temp.push(
                <div key={`sides${i}-${j}`} style={{ display: "block", width: "100%" }}>
                    <p>
                        <b>
                            {sides[i].items[j].name}
                        </b>
                    </p>
                    <p>
                        {sides[i].items[j].description}
                    </p>
                </div>

            )
        }
        food.push(
            <Accordian style={{ width: "100%" }} >
                <AccordionSummary key={`Entrees${i}`}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                        backgroundColor: "#f5f5f5",
                        border: "1px solid #e0e0e0",
                    }}
                >
                    {sides[i].name}
                </AccordionSummary>)
                <AccordionDetails key={`Entrees${i}-A`}>
                    <div style={{ display: "block", width: "100%" }}>
                        {temp}
                    </div>

                </AccordionDetails>
            </Accordian>

        )



    }


    return <div style={{ width: "100%" }}>
        {food}
    </div>
}
function Desserts() {
    let food: any = [];
    for (let i = 0; i < desserts.length; i++) {
        food.push(
            <div key={`Desserts${i}`}>
                <p>
                    <b>
                        {desserts[i].name}
                    </b>
                </p>
            </div>
        )
    }
    return <div style={{ width: "100%" }}>
        {food}
    </div>
}
function Menu() {

    //currentMenu state
    const [currentMenu, setCurrentMenu] = useState("Appetizers")

    function CurrentMenuSelection() {
        console.log(currentMenu);
        switch (currentMenu) {
            case "Appetizers": return <Appetizers />
            case "Salads": return <Salads />
            case "Entrees": return <Entrees />
            case "Sides": return <Sides />
            case "Desserts": return <Desserts />
            default: return <div>Error</div>

        }
    }




    return <div >
        <Container>
            <Row style={{ justifyContent: "center", marginBottom: "1em" }}>
                <h2>
                    Menu
                </h2>
            </Row>
            <Row style={{ justifyContent: "center" }}>
                <MenuCategoryTitle sm={2} setCurrentMenu={setCurrentMenu} currentMenu={currentMenu} name="Appetizers"></MenuCategoryTitle>
                <MenuCategoryTitle sm={2} setCurrentMenu={setCurrentMenu} currentMenu={currentMenu} name="Salads"></MenuCategoryTitle>
                <MenuCategoryTitle sm={2} setCurrentMenu={setCurrentMenu} currentMenu={currentMenu} name="Entrees"></MenuCategoryTitle>
                <MenuCategoryTitle sm={2} setCurrentMenu={setCurrentMenu} currentMenu={currentMenu} name="Sides"></MenuCategoryTitle>
                <MenuCategoryTitle sm={2} setCurrentMenu={setCurrentMenu} currentMenu={currentMenu} name="Desserts"></MenuCategoryTitle>

            </Row>
            <Row style={{ marginTop: "2em" }}>


                <CurrentMenuSelection ></CurrentMenuSelection>

            </Row>
        </Container>

    </div>
}
export default Menu