
import { Col } from "reactstrap"

function MenuCategoryTitle(props: { name: string, sm: number, currentMenu: string, setCurrentMenu: React.Dispatch<React.SetStateAction<string>> }) {
    let color = "white"

    if (props.currentMenu == props.name) {
        console.log("this was called");
        color = "lightgray"
    }
    function menuMouseOver(e: React.MouseEvent<HTMLDivElement>) {

        // change the background color
        e.currentTarget.style.backgroundColor = "lightgray";

    }
    function menuMouseOut(e: React.MouseEvent<HTMLDivElement>) {

        //change the background color
        if (props.currentMenu != props.name) {

            e.currentTarget.style.backgroundColor = "white";
        }

    }
    function menuMouseClick(e: React.MouseEvent<HTMLDivElement>) {
        props.setCurrentMenu(e.currentTarget.innerText)
    }
    let menuCategoriesStyle: React.CSSProperties = {
        "border": "0.1em solid #ccc",
        // center the text vertically and horizontally
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "padding": "0.5em",
        "margin": "0em",
        "fontSize": "1.5em",
        "fontWeight": "bold",
        "backgroundColor": color,

    }


    return (<Col onClick={menuMouseClick} onMouseOver={menuMouseOver} onMouseOut={menuMouseOut} style={menuCategoriesStyle}>
        {props.name}

    </Col>)

}
export default MenuCategoryTitle;