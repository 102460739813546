import HomePageHeader from "components/Headers/HomePageHeader"
import Menu from "components/menu/Menu";
import steve from "assets/img/steve.png";
import jared from "assets/img/jared.png";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";
import Footer from "components/Footers/Footer";
const contactName = { fontSize: "2.5em" }
const contactDetails = {
    fontSize: "1.5em",
    fontweight: "italic",
}
function PhotoSlider() {

    return (
        <div>

        </div>
    )

}

function Homepage() {
    return (
        <div>
            <HomePageHeader />
            <div className="main">

                <div id="menu" className="section section-light text-center">
                    <Menu />
                </div>

                <div className="section section-dark text-center" id="contactus">
                    <Container>
                        <h2 className="title">Contact Us</h2>
                        <Row>

                            <Col md="6">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#steve" onClick={(e) => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                src={
                                                    steve
                                                }
                                            />
                                        </a>
                                    </div>
                                    <CardBody>
                                        <a href="#steve" onClick={(e) => e.preventDefault()}>
                                            <div className="author">
                                                <CardTitle tag="h4" style={contactName}>Steve Grgich</CardTitle>
                                                <div style={contactDetails} className="card-category" >
                                                    Cell: (209)505-1498
                                                    <br></br>
                                                    Email: stevegrgich@gmail.com</div>
                                            </div>
                                        </a>

                                    </CardBody>

                                </Card>
                            </Col>
                            <Col md="6">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#jared" onClick={(e) => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                src={
                                                    jared
                                                }
                                            />
                                        </a>
                                    </div>
                                    <CardBody>
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <div className="author">
                                                <CardTitle style={contactName} tag="h4">Jared Grgich</CardTitle>
                                                <div style={contactDetails} className="card-category">
                                                    Cell: (209)996-9487<br></br>
                                                    Email: grgichcatering@hotmail.com</div>
                                            </div>
                                        </a>

                                    </CardBody>

                                </Card>
                            </Col>

                        </Row>
                    </Container>
                </div>

            </div>
        
        </div>
    )
}


export default Homepage